/**
 * Tools
 *
 * Useful functions that belong to any specific module
 *
 * @author Chris Nasr <ouroboroscode@gmail.com>
 * @copyright OuroborosCoding
 * @created 2018-11-25
 */

// Regex constants
const _rePhone = /^(1)?(\d{3})(\d{3})(\d{4})$/

// Time constants
const MS_PER_DAY = 86400000;
const MS_PER_WEEK = MS_PER_DAY * 7;

/**
 * To Date
 *
 * Converts values to a Date type
 *
 * @name _toDate
 * @access private
 * @param mixed val An integer or string
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return Date
 */
function _toDate(val, utc=true) {

	// If we got a timestamp
	if(typeof val === 'number') {
		return new Date(val*1000);
	}

	// If we got a string
	if(typeof val === 'string') {

		// If it's only ten characters, add the time and timezone
		if(val.length === 10) {
			return new Date(val + 'T00:00:00' + (utc ? '-0000' : ''));
		}

		// If it's 19 characters, replace any space with T and add the timezone
		if(val.length === 19) {
			return new Date(val.replace(' ', 'T') + (utc ? '-0000' : ''));
		}

		// If it's over 19 characters and has a period
		if(val.length > 19 && val.substr(19,1) === '.') {
			return new Date(val.substr(0,19).replace(' ', 'T') + (utc ? '-0000' : ''));
		}

		// If it's 24 characters, assume it's good
		if(val.length === 24) {
			return new Date(val);
		}

		// Raise an exception
		throw new Error('Invalid date string: ' + val);
	}

	// If it's a date, do nothing
	if(val instanceof Date) {
		return val;
	}

	// Raise an exception
	console.error('Invalid date', val);
	return new Date(0);
}

/**
 * Array Find Index
 *
 * Finds a specific object in an array based on key name and value and
 * returns its index
 *
 * @name afindi
 * @param array a				The value to look through
 * @param str k					The name of the key to check
 * @param mixed v				The value to check against
 * @param bool ignore_case		Optional, if true assumes strings and compares without case
 * @return object
 */
export function afindi(a, k, v, ignore_case=false) {

	if(ignore_case) {
		v = v.toLowerCase();
		for(let i = 0; i < a.length; ++i) {
			if(a[i][k].toLowerCase() === v) {
				return i;
			}
		}
	} else {
		for(let i = 0; i < a.length; ++i) {
			if(a[i][k] === v) {
				return i;
			}
		}
	}
	return -1;
}

/**
 * Array Find Object
 *
 * Finds a specific object in an array based on key name and value and
 * returns it
 *
 * @name afindo
 * @param array a				The value to look through
 * @param str k					The name of the key to check
 * @param mixed v				The value to check against
 * @param bool ignore_case		Optional, if true assumes strings and compares without case
 * @return object
 */
export function afindo(a, k, v, ignore_case=false) {
	if(ignore_case) {
		v = v.toLowerCase();
		for(let i = 0; i < a.length; ++i) {
			if(a[i][k].toLowerCase() === v) {
				return a[i];
			}
		}
	} else {
		for(let i = 0; i < a.length; ++i) {
			if(a[i][k] === v) {
				return a[i];
			}
		}
	}
	return null;
}

/**
 * Clone
 *
 * Deep clone any type of object, returning a new one
 *
 * @name clone
 * @param mixed o				The variable to clone
 * @return mixed
 */
export function clone(o) {
	// New var
	let n = null;

	// If it's an array
	if(Array.isArray(o)) {
		n = [];
		for(let i in o) {
			n.push(clone(o[i]));
		}
	}

	// Else if the value is an object
	else if(isObject(o)) {
		n = {}
		for(let k in o) {
			n[k] = clone(o[k]);
		}
	}

	// Else, copy as is
	else {
		n = o;
	}

	// Return the new var
	return n;
}

/**
 * Compare
 *
 * Compares two values of any type to see if they contain the same
 * data or not
 *
 * @name compare
 * @access public
 * @param mixed v1				The first value
 * @param mixed v2				The second value
 * @return bool
 */
export function compare(v1, v2) {

	// If they're both arrays
	if(Array.isArray(v1) && Array.isArray(v2)) {

		// If they don't have the same length
		if(v1.length !== v2.length) {
			return false;
		}

		// Compare the values
		for(let i = 0; i < v1.length; ++i) {
			if(!compare(v1[i], v2[i])) {
				return false;
			}
		}
	}

	// Else if they're both objects
	else if(isObject(v1) && isObject(v2)) {

		// If they don't have the same keys
		if(!compare(Object.keys(v1).sort(), Object.keys(v2).sort())) {
			return false;
		}

		// Compare each key
		for(let k in v1) {
			if(!compare(v1[k], v2[k])) {
				return false;
			}
		}
	}

	// Else, compare as is
	else {
		if(v1 !== v2) {
			return false;
		}
	}

	// Return equal
	return true;
}

/**
 * Date
 *
 * Returns a nicely formatted date string from a timestamp or Date object
 *
 * @name date
 * @access public
 * @param Date|Number d A Date instance or a timestamp value
 * @param String separator Optional value separator, defaults to -
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return String
 */
export function date(d, separator='-', utc=true) {

	// Make sure we have a Date instance
	d = _toDate(d, utc);

	// Generate the date and return it
	var Y = '' + d.getFullYear();
	var M = '' + (d.getMonth() + 1);
	if(M.length === 1) M = '0' + M;
	var D = '' + d.getDate();
	if(D.length === 1) D = '0' + D;
	return Y + separator + M + separator + D;
}

/**
 * Date: Day of Week
 *
 * Returns the day of the week in the current week, regardless of past or future
 *
 * @name DateDOW
 * @access public
 * @param Number dow The day of the week we want
 * @return Date
 */
export function dateDOW(dow) {

	// If the day of the week is invalid
	if(dow < 0 || dow > 6) {
		throw new Error('dateDOW dow param can not be less than 0 or more than 6');
	}

	// Get todays date
	let oToday = new Date();

	// Reset the hours/minutes/seconds
	oToday.setHours(0,0,0);

	// Subtract the day requested from the current one
	let iDiff = oToday.getDay() - dow;

	// Subtract the amount of days from the current day to get the timestamp
	let iTS = oToday.getTime() - (iDiff * MS_PER_DAY);

	// Return the new date
	return new Date(iTS);
}

/**
 * Date Increment
 *
 * Returns a date incremented by the given days. Use negative to decrement
 *
 * @name dateInc
 * @access public
 * @param uint days The number of days to increment by
 * @return Date
 */
export function dateInc(days=1) {
	let oDate = new Date();
	oDate.setDate(oDate.getDate() + days);
	return oDate;
}

/**
 * Date: Next Day of Week
 *
 * Allows you to get the date of a specific day of the week in the next
 * week, or many weeks in the future
 *
 * @name dateNextDOW
 * @access public
 * @param Number dow The day of the week (Sunday is 0, Saturday is 6)
 * @param Number weeks Optional number of weeks in the past, defaults to 1
 * @return Date
 */
export function dateNextDOW(dow, weeks=1) {

	// If the day of the week is invalid
	if(dow < 0 || dow > 6) {
		throw new Error('datePreviousDOW dow param can not be less than 0 or more than 6');
	}

	// If weeks is less than 1, throw an error
	if(weeks < 1) {
		throw new Error('datePreviousDOW weeks param can not be less than 1');
	}

	// Get todays date
	let oToday = new Date();

	// Reset the hours/minutes/seconds
	oToday.setHours(0,0,0);

	// Subtract the day requested from the current one
	let iDiff = 7 + (dow - oToday.getDay());

	// Subtract the amount of days from the current day to get the timestamp
	let iTS = oToday.getTime() + (iDiff * MS_PER_DAY);

	// If we have weeks
	if(weeks > 1) {
		iTS += (weeks - 1) * MS_PER_WEEK;
	}

	// Return the new date
	return new Date(iTS);
}

/**
 * Date: Previous Day of Week
 *
 * Allows you to get the date of a specific day of the week in the previous
 * week, or many previous weeks, before
 *
 * @name datePreviousDOW
 * @access public
 * @param Number dow The day of the week (Sunday is 0, Saturday is 6)
 * @param Number weeks Optional number of weeks in the past, defaults to 1
 * @return Date
 */
export function datePreviousDOW(dow, weeks=1) {

	// If the day of the week is invalid
	if(dow < 0 || dow > 6) {
		throw new Error('datePreviousDOW dow param can not be less than 0 or more than 6');
	}

	// If weeks is less than 1, throw an error
	if(weeks < 1) {
		throw new Error('datePreviousDOW weeks param can not be less than 1');
	}

	// Get todays date
	let oToday = new Date();

	// Reset the hours/minutes/seconds
	oToday.setHours(0,0,0);

	// Subtract the day requested from the current one
	let iDiff = 7 + (oToday.getDay() - dow);

	// Subtract the amount of days from the current day to get the timestamp
	let iTS = oToday.getTime() - (iDiff * MS_PER_DAY);

	// If we have weeks
	if(weeks > 1) {
		iTS -= (weeks - 1) * MS_PER_WEEK;
	}

	// Return the new date
	return new Date(iTS);
}

/**
 * Date Time
 *
 * Returns a nicely formatted date/time string from a timestamp or Date object
 *
 * @name datetime
 * @access public
 * @param Date|Number d A Date instance or a timestamp value
 * @param String separator Optional value separator, defaults to -
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return String
 */
export function datetime(d, separator='-', utc=true) {

	// Make sure we have a Date instance
	d = _toDate(d, utc);

	// Generate the time
	var t = ['', '', ''];
	t[0] += d.getHours();
	if(t[0].length === 1) t[0] = '0' + t[0];
	t[1] += d.getMinutes();
	if(t[1].length === 1) t[1] = '0' + t[1];
	t[2] += d.getSeconds();
	if(t[2].length === 1) t[2] = '0' + t[2];

	// Generate the date and add the hours and time zone
	return date(d, separator) + ' ' + t.join(':') + ' ' + tzShort(d);
}

/**
 * Convert DateTime
 *
 * Returns a nicely formatted date/time US string from a timestamp or Date object
 *
 * @name convertDateTime
 * @access public
 * @param Date|Number d A Date instance or a timestamp value
 * @return String
 */
export function convertDateTime(oldDate) {
	let q = new Date(Date.parse(oldDate));

	// check if date is valid because some browser have issues converting the Date
	// The solution that worked for me was replacing the space in the dateString with "T". ( example : dateString.replace(/ /g,"T") )
	if (isNaN(q.getTime())) {
		oldDate = oldDate.replace(/ /g,"T")
		q = new Date(Date.parse(oldDate));
	}

	const newDate = `${q.toLocaleDateString("en-US")} ${q.toLocaleTimeString("en-US")}`;
	return newDate;
}

/**
 * Convert DateTime From Unix Timestamp
 *
 * Returns a nicely formatted date/time US string from a timestamp or Date object
 *
 * @name convertDateTime
 * @access public
 * @param Date|Number d A Date instance or a timestamp value
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return String
 */
export function convertDateTimeFromUnix(oldDate, utc = true) {
	// Make sure we have a Date instance
	const d = _toDate(oldDate, utc);
	const q = new Date(Date.parse(d));
	const newDate = `${q.toLocaleDateString("en-US")} ${q.toLocaleTimeString("en-US")}`;
	return newDate;
}

/**
 * Divmod
 *
 * Take two (non complex) numbers as arguments and return a pair of numbers
 * consisting of their quotient and remainder when using integer division. 100%
 * stolen from python
 *
 * @name divmod
 * @access public
 * @param uint x The dividend
 * @param uint y The divisor
 * @return Array
 */
export function divmod(x, y) {
	return [
		~~(x / y),
		x % y
	]
}

/**
 * Empty
 *
 * Returns true if the value type is empty
 *
 * @name empty
 * @access public
 * @param mixed m				The value to check, can be object, array, string, etc
 * @return bool
 */
export function empty(m) {

	// If it's an object
	if(isObject(m)) {
		for(let p in m) {
			return false;
		}
		return true;
	}

	// Else if it's an array or a string
	else if(Array.isArray(m) || typeof m == 'string') {
		return m.length === 0;
	}

	// Else
	else {

		// If it's null or undefined
		if(typeof m == 'undefined' || m == null) {
			return true;
		}

		// Else return false
		return false;
	}
}

/**
 * Is Decimal
 *
 * Returns true if the variable is a number
 *
 * @name isDecimal
 * @access public
 * @param mixed m				The variable to test
 * @return bool
 */
export function isDecimal(m) {
	return typeof m == 'number';
}

/**
 * Is Integer
 *
 * Returns true if the variable is a true integer
 *
 * @name isInteger
 * @access public
 * @param mixed m				The variable to test
 * @return bool
 */
export function isInteger(m) {
	return m === +m && m === (m|0);
}

/**
 * Is Object
 *
 * Returns true if the variable is a true object
 *
 * @name isObject
 * @access public
 * @param mixed m				The variable to test
 * @return bool
 */
export function isObject(m) {
	if(m === null) return false;
	if(typeof m != 'object') return false;
	if(Array.isArray(m)) return false;
	return true;
}

/**
 * Is Today
 *
 * Returns true if the passed date corresponds to the current date
 *
 * @name isToday
 * @access public
 * @param Date|String|Number d A date object or a string/int that can be
 *                             converted to a Date
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return bool
 */
export function isToday(d, utc=true) {

	// Today's date
	const oToday = new Date();

	// Make sure we have a Date instance
	d = _toDate(d, utc);

	// Compare date, month, and year
	return d.getDate() === oToday.getDate() &&
			d.getMonth() === oToday.getMonth() &&
			d.getFullYear() === oToday.getFullYear();
}

/**
 * Nice Date
 *
 * Returns a date formatted in the client's local format
 *
 * @name niceDate
 * @access public
 * @param Number|String|date d The date value
 * @param String text The type of format
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return String
 */
export function niceDate(d, text='long', utc=true) {

	// Make sure we have a Date instance
	d = _toDate(d, utc);

	// Return the string
	return d.toLocaleDateString('en-US', {
		day: 'numeric',
		month: text,
		weekday: text,
		year: 'numeric'
	});
}

/**
 * Nice Date/Time
 *
 * Returns a date and time formatted in the client's local format
 *
 * @name niceDateTime
 * @access public
 * @param Number|String|date d The date value
 * @param String text The type of format
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return String
 */
export function niceDateTime(d, text='long', utc=true) {

	// Make sure we have a Date instance
	d = _toDate(d, utc);

	// Generate the time
	var t = ['', '', ''];
	t[0] += d.getHours();
	if(t[0].length === 1) t[0] = '0' + t[0];
	t[1] += d.getMinutes();
	if(t[1].length === 1) t[1] = '0' + t[1];
	t[2] += d.getSeconds();
	if(t[2].length === 1) t[2] = '0' + t[2];

	// Return the date and time
	return d.toLocaleDateString('en-US', {
		day: 'numeric',
		month: text,
		weekday: text,
		year: 'numeric'
	}) + ' ' + t.join(':');
}

/**
 * Nice Phone
 *
 * Returns a more easily readable phone number in the NA format
 *
 * @name nicePhone
 * @access public
 * @param String val The digits of the phone number to convert
 * @return String
 */
export function nicePhone(val) {
	let lMatch = _rePhone.exec(val);
	if(!lMatch) {
		return val;
	}
	return (lMatch[1] ? '1 ' : '') + '(' + lMatch[2] + ') ' + lMatch[3] + '-' + lMatch[4];
}

/**
 * Object Map
 *
 * Works like map for arrays, but iterates over an object returning the value,
 * the key, and the index, in that order.
 *
 * @name omap
 * @access public
 * @param Object o				The object to map
 * @param Function callback		The function to call each iteration
 * @return Array
 */
export function omap(o, callback) {
	let ret = [];
	let index = 0;
	for(let k in o) {
		ret.push(callback(o[k], k, index++));
	}
	return ret;
}

/**
 * Safe Local Storage
 *
 * Fetches a value from local storage or returns the default if no value is
 * found
 *
 * safeLocalStorage
 * @access public
 * @param String name			The name of the local var to fetch
 * @param String default_		The value to return if the var is not found
 * @return String
 */
export function safeLocalStorage(name, default_) {
	let value = localStorage.getItem(name);
	return value === null ? default_ : value;
}

/**
 * Safe Local Storage Bool
 *
 * Fetches a value from local storage or returns the default if no value is
 * found. Assumes data is a boolean value
 *
 * @name safeLocalStorageBool
 * @param String name			The name of the local var to fetch
 * @param String default_		The value to return if the var is not found
 * @return bool
 */
export function safeLocalStorageBool(name, default_=false) {
	let value = localStorage.getItem(name);
	return value === null ? default_ : (
		value === '' ? false : true
	)
}

/**
 * Safe Local Storage JSON
 *
 * Fetches a value from local storage or returns the default if no value is
 * found. Assumes data is stored in JSON
 *
 * safeLocalStorageJSON
 * @access public
 * @param String name			The name of the local var to fetch
 * @param String default_		The value to return if the var is not found
 * @return String
 */
export function safeLocalStorageJSON(name, default_) {
	let value = localStorage.getItem(name);
	return value === null ? default_ : JSON.parse(value);
}

/**
 * Sort By Key
 *
 * Returns a callback function that will compare two objects by the key name
 * pass
 *
 * @name sortByKey
 * @access public
 * @param string key The name of the key to sort by
 * @return Function
 */
export function sortByKey(key) {
	return (a, b) => {
		if(a[key] === b[key]) return 0;
		else return (a[key] < b[key]) ? -1 : 1;
	}
}

/**
 * Time Elapsed
 *
 * Takes the time in seconds and converts it to human readable hours, minutes,
 * second
 *
 * @name timeElapsed
 * @access public
 * @param uint seconds The seconds elapsed
 * @return String
 */
export function timeElapsed(seconds) {

	// Get the hours and remaining seconds
	let [h, r] = divmod(seconds, 3600);

	// Get the minutes and seconds
	let [m, s] = divmod(r, 60);

	// Convert the values to strings
	h = h.toString();
	m = m < 10 ? '0' + m.toString() : m.toString()
	s = s < 10 ? '0' + s.toString() : s.toString()

	// Put them all together and return
	return h + ':' + m + ':' + s;
}

/**
 * Time Zone Short
 *
 * Returns the short form of the timezone, EST, UTC, etc.
 *
 * @name tzShort
 * @access public
 * @param Number|String|date d The date value
 * @param bool utc Optional, default set to true, assumes GMT timezone where missing
 * @return String
 */
export function tzShort(d, utc=true) {

	// Make sure we have a Date instance
	d = _toDate(d, utc);

	// Turn it into a string
	let s = d + ''

	// Get the abbreviation
	let m = s.match(/\(([^)]+)\)$/)

	// If it didn't work, try for IE
	if(!m) {
		s.match(/([A-Z]+) [\d]{4}$/);
	}

	// If we got a value
	if(m) {

		// Return just the capital letters
		return m[0].match(/[A-Z]/g).join('');
	} else {
		return '';
	}
}

/**
 * UCFirst
 *
 * Makes the first character of each word in the text upper case
 *
 * @name ucfirst
 * @access public
 * @param String text			The text to convert
 * @return String
 */
export function ucfirst(text) {
	if(!text) {
		return '';
	}
	let lParts = text.split(' ');
	return lParts.map(s =>
		s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
	).join(' ');
}

/**
 * UUID v4
 *
 * Returns a psuedo random string in UUID format (NOT ACTUALLY A UUID)
 *
 * @name uuidv4
 * @access public
 * @return str
 */
export function uuidv4() {
	return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
		// eslint-disable-next-line
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	);
}

// Default export
const Tools = {
	afindi: afindi,
	afindo: afindo,
	clone: clone,
	compare: compare,
	date: date,
	dateInc: dateInc,
	dateDOW: dateDOW,
	dateNextDOW: dateNextDOW,
	datePreviousDOW: datePreviousDOW,
	datetime: datetime,
	divmod: divmod,
	empty: empty,
	isDecimal: isDecimal,
	isInteger: isInteger,
	isObject: isObject,
	isToday: isToday,
	niceDate: niceDate,
	niceDateTime: niceDateTime,
	nicePhone: nicePhone,
	omap: omap,
	safeLocalStorage: safeLocalStorage,
	safeLocalStorageBool: safeLocalStorageBool,
	safeLocalStorageJSON: safeLocalStorageJSON,
	sortByKey: sortByKey,
	timeElapsed: timeElapsed,
	tzShort: tzShort,
	ucfirst: ucfirst,
	uuidv4: uuidv4
};
export default Tools;
