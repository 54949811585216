/**
 * Main
 *
 * Main page of the patient portal
 *
 * @author Chris Nasr <bast@maleexcel.com>
 * @copyright MaleExcelMedical
 * @created 2021-01-20
 */

// NPM modules
import React from 'react';

// Material UI
// import AppBar from '@material-ui/core/AppBar';
// import Box from '@material-ui/core/Box';
// import Tab from '@material-ui/core/Tab';
// import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Composite component modules
// import NoUser from 'components/dialogs/NoUser';

// Tab component modules
// import Dashboard from './Dashboard';
// import HRT from './HRT';
// import Personal from './Personal';
// import Prescriptions from './Prescriptions';

// Theme
const useStyles = makeStyles((theme) => ({
	content: {
		flexBasis: 0,
		flexGrow: 1,
		flexShrink: 1,
		height: '100%',
		overflow: 'auto',
		padding: '10px'
	},
	tabs: {
		flexGrow: 0,
		flexShrink: 0
	},
	paper: {
		width: '100%',
		height: '100%',
		paddingTop: '5%',
		textAlign: 'center'
	},
	text: {
		textAlign: 'left'
	},
	contactContainer: {
		margin: '0 auto',
		width: '35%',
		border: 'none',
		borderRadius: '25px',
		padding: '3% 2%',
		fontWeight: '500',
		fontSize: '16px'
	},
	textHeader: {
		fontSize: '25px'
	},
	textSubHeader: {
		fontSize: '18px'
	}
}));

/**
 * Main
 *
 * Shows tabs for different data types
 *
 * @name Main
 * @access public
 * @param Object props Attributes sent to the component
 * @returns React.Component
 */
export default function Main(props) {

	// Styles
	const classes = useStyles();

	// // State
	// let [tab, tabSet] = useState(0);
	//
	// // Tabs
	// let lTabs = ['dashboard', 'personal'];
	// if(props.user.rx_id) {
	// 	lTabs.push('rx');
	// }
	// if(props.user.hrt) {
	// 	lTabs.push('hrt');
	// }

	return (
		<React.Fragment>
			<Paper className={classes.paper}>
				<Grid className={classes.grid} container spacing={0}>
					<Grid className={classes.textHeader} item xs={12}><strong>This function has been disabled as we migrate to our new Patient Portal coming very soon</strong></Grid>
					<Grid item xs={4}><br/></Grid>
					<Grid className={classes.textSubHeader} item xs={12}>Please text us at (833) 394-7744 and a member of our Patient Support team will reach out to you shortly.</Grid>
					<Grid className={classes.textSubHeader} item xs={12}>Our normal response times are within the hour Monday through Friday from 9AM-5PM EST.</Grid>
					<Grid item xs={4}><br/></Grid>
					<Grid container spacing={1} xs={12}>
						<Grid className={[classes.grid, classes.text, classes.contactContainer].join(' ')} container spacing={1}>
							<Grid className={classes.textHeader} item xs={12}><strong>Contact us</strong></Grid>
							<Grid item xs={12}><br/></Grid>
							<Grid item xs={6}>
								<Grid item><strong>Phone:</strong> +1(833) 625-3392</Grid>
								<Grid item><strong>Text:</strong> +1(833) 394-7744</Grid>
								<Grid item><br/></Grid>
								<Grid item>Mon-Fri: 9am - 5pm EST</Grid>
							</Grid>

							<Grid item xs={6}>
								<Grid item><strong>Address</strong></Grid>
								<Grid item>809 Westmere Ave</Grid>
								<Grid item>Suite A</Grid>
								<Grid item>Charlote, NC 28208</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Paper>
		</React.Fragment>
	)

	// Render
	// if(props.user) {
	// 	return (
	// 		<React.Fragment>
	// 			<AppBar position="static" color="default" className={classes.tabs}>
	// 				<Tabs
	// 					onChange={(ev, newTab) => tabSet(newTab)}
	// 					value={tab}
	// 					variant="fullWidth"
	// 				>
	// 					<Tab label="Dashboard" />
	// 					<Tab label="Personal" />
	// 					{props.user.rx_id &&
	// 						<Tab label="Prescriptions" />
	// 					}
	// 					{props.user.hrt &&
	// 						<Tab label="HRT" />
	// 					}
	// 				</Tabs>
	// 			</AppBar>
	// 			<Box className={classes.content}>
	// 				{lTabs[tab] === 'dashboard' &&
	// 					<Dashboard user={props.user} />
	// 				}
	// 				{lTabs[tab] === 'personal' &&
	// 					<Personal user={props.user} />
	// 				}
	// 				{lTabs[tab] === 'rx' &&
	// 					<Prescriptions user={props.user} />
	// 				}
	// 				{lTabs[tab] === 'hrt' &&
	// 					<HRT user={props.user} />
	// 				}
	// 			</Box>
	// 		</React.Fragment>
	// 	)
	// } else {
	// 	return <NoUser />
	// }
}
